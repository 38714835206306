const productForm = {
  methods: {
    apiViolationToErrors(violations) {
      for (const violation of violations) {
        switch (violation.propertyPath) {
        case 'description':
          this.errors.description = violation.message
          break
        case 'title':
          this.errors.title = violation.message
          break
        case 'defects':
          this.errors.defects = violation.message
          break
        case 'category':
          this.errors.categoryId = violation.message
          break
        case 'family':
          this.errors.family = violation.message
          break
        case 'brand':
          this.errors.brandId = violation.message
          break
        case 'condition':
          this.errors.conditionId = violation.message
          break
        case 'years':
          this.errors.yearsId = violation.message
          break
        case 'materials':
          this.errors.materialIds = violation.message
          break
        case 'look':
          this.errors.lookId = violation.message
          break
        case 'weight':
          this.errors.weight = violation.message
          break
        case 'colors':
          this.errors.colorIds = violation.message
          break
        case 'patterns':
          this.errors.patternIds = violation.message
          break
        case 'styles':
          this.errors.styleIds = violation.message
          break
        case 'photos':
          this.errors.photos = violation.message
          break
        case 'store':
          this.errors.storeId = violation.message
          break
        case 'drops':
          this.errors.dropIds = violation.message
          break
        case 'target':
          this.errors.target = violation.message
          break
        default: {
          if (this.handleCollectionError(violation, 'variants', 'variants')) {
            break
          }
          if (this.handleCollectionError(violation, 'translations', 'translations')) {
            break
          }
          this.snackbarError('internal error')
          console.error('malformed violation property')
          break
        }}
      }
    },
    handleCollectionError(violation, prefix, property) {
      if (!violation.propertyPath.startsWith(prefix + '[')) {
        return false
      }

      const indexes = [...violation.propertyPath.matchAll(/\[(?<index>\w+)\]/g)].map(r => r.groups.index)
      if (indexes.length <= 1) {
        this.snackbarError('internal error')
        console.error('malformed violation property')

        return false
      }

      if (!this.errors[property][indexes[0]]) {
        this.errors[property][indexes[0]] = {}
      }
      let fullObject = this.errors[property][indexes[0]]
      let subObject = fullObject
      for (let i = 1; i < indexes.length; i++) {
        const nextObject = i < indexes.length - 1 ? fullObject[indexes[i]] || {} : violation.message
        subObject[indexes[i]] = nextObject
        subObject = nextObject
      }
      this.errors[property] = JSON.parse(JSON.stringify(this.errors[property]))

      return true
    },
  },
}

export default productForm
